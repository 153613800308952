import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Button, Mixins } from '@cheapreats/react-ui';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape-2.svg';
import { media } from '../../utils/mixins';
import { AiOutlineMail } from 'react-icons/ai';

export const Interested = () => {
    const { bar } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="Interested?"
            paragraphText={`Start your new journey and learn skills that
                matter for you at CheaprEats. Send us your
                resume today!`}
            textComponent={
                <Button color="secondaryBackground" padding="15px 30px">
                    <ButtonRow>
                        <Icon as={AiOutlineMail} />
                        Contact Us
                    </ButtonRow>
                </Button>
            }
            imageContent={<Image fluid={bar.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        bar: file(relativePath: { eq: "bar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        appstore: file(relativePath: { eq: "appstore.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Icon = styled.svg`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const ButtonRow = styled.div`
    ${Mixins.flex('row', 'center')}
    ${({ theme }) => `
        color: ${theme.colors.black};
    `}
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
