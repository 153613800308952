import React from 'react';
import styled from 'styled-components';
import { SectionTemplate, SectionTextContent } from '../../components';
import { Heading, Mixins } from '@cheapreats/react-ui';
export const Description = () => {
    return (
        <SectionTemplate
            header={
                <SectionTextContent
                    headingText="DESCRIPTION"
                    paragraphText={`You will be responsible for expanding CheaprEats to an
                        entirely new campus. You will sell restaurants and
                        launch them on our platform. To be successfull in this
                        role, you'll need to be able to manage multiple projects
                        at once, negotiate, and have a drive to win. You'll work
                        closely with our Head of Sales to discover key insights
                        and rollout campus expansion.`}
                />
            }
        >
            <Content>
                <ListContainer>
                    <Heading type="h2" color="primary">
                        Overview of Role
                    </Heading>
                    <List>
                        <ListItem>
                            Excellent communication and negotiation skills
                        </ListItem>
                        <ListItem>
                            Highly motivated, persistent, and target driven
                        </ListItem>
                        <ListItem>Relationship management skills</ListItem>
                    </List>
                </ListContainer>
                <ListContainer>
                    <Heading type="h2" color="primary">
                        What You'll Do
                    </Heading>
                    <List>
                        <ListItem>
                            Restaurant research, lead generation, acquisition
                        </ListItem>
                        <ListItem>
                            Restaurant sales and set up, logistics
                        </ListItem>
                        <ListItem>
                            Run operations and maintenance for restaurants
                        </ListItem>
                        <ListItem>
                            Design growth and launch plans for the fall
                        </ListItem>
                    </List>
                </ListContainer>
            </Content>
        </SectionTemplate>
    );
};

const Content = styled.div`
    margin-top: 20px;
    ${Mixins.flex('row', 'flex-start')}
    ${Mixins.media('phone', Mixins.flex('column'))}
`;

const ListContainer = styled.div`
    ${Mixins.flex('column')}
    margin-right: 30px;
    ${Mixins.media('phone', 'margin-right: auto')}
`;

const List = styled.ul`
    ${({ theme }) => `color: ${theme.colors.black}`}
`;

const ListItem = styled.li`
    ${({ theme }) => `
        color: ${theme.colors.black};
        font-size: ${theme.font.size.default};
    `}
`;
