import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { SectionTemplate, SectionTextContent } from '../../components';
import { media } from '../../utils/mixins';
import { Mixins } from '@cheapreats/react-ui';
import mask from '../../images/blob-shape-2.svg';

export const Apply = () => {
    const { bar } = useStaticQuery(query);
    return (
        <SectionTemplate
            header={
                <SectionTextContent
                    headingText="How to apply?"
                    paragraphText={`Send the CheaprEats team an email with the
                        following information:`}
                />
            }
            footer={
                <Content>
                    <Image fluid={bar.childImageSharp.fluid} />
                    <Image fluid={bar.childImageSharp.fluid} />
                    <Image fluid={bar.childImageSharp.fluid} />
                    <Image fluid={bar.childImageSharp.fluid} />
                    <Image fluid={bar.childImageSharp.fluid} />
                </Content>
            }
        >
            <List>
                <ListItem>
                    <strong>Email Title:</strong> "CE Ambassador Application -
                    Your_Name"
                </ListItem>
                <ListItem>
                    <strong>Email Body:</strong> A paragraph explaining why you
                    want to be a part of CheaprEats, your core values as a human
                    being, what you want to get out of this (Experience, Money,
                    Skills etc).
                </ListItem>
                <ListItem>
                    <strong>Email Footer:</strong> Links to Social Media
                    (LinkedIn - Required, Facebook, Instagram etc) Linking your
                    portfolio/Personal Website will be a major plus!
                </ListItem>
                <ListItem>
                    <strong>Email Attachments:</strong> Your updated resume
                </ListItem>
            </List>
        </SectionTemplate>
    );
};

const query = graphql`
    {
        bar: file(relativePath: { eq: "bar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Content = styled.div`
    margin-top: 20px;
    ${Mixins.flex('row', 'flex-start')}
    ${Mixins.media('phone', Mixins.flex('column'))}
`;

const List = styled.ul`
    ${({ theme }) => `color: ${theme.colors.black}`}
`;

const ListItem = styled.li`
    ${({ theme }) => `
        color: ${theme.colors.black};
        font-size: ${theme.font.size.default};
    `}
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;
