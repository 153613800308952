import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape-2.svg';
import { media } from '../../utils/mixins';
import { Paragraph } from '@cheapreats/react-ui';

export const Overview = () => {
    const { bar } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="PROGRAM OVERVIEW"
            textComponent={
                <Paragraph
                    size="default"
                    lineHeight="1.6"
                    color="black"
                    margin="20px auto"
                >
                    <StyledLink to="/">
                        <LinkText>{`CheaprEats `}</LinkText>
                    </StyledLink>
                    is launching a unique type of
                    <StyledLink to="/">
                        <LinkText>{` Ambassador Program `}</LinkText>
                    </StyledLink>
                    that not only will allow the Ambassadors to gain real life
                    experience in the start-up arena but also some profits
                    reflecting the hard work they've put in! Let's get rid of
                    crappy Ambassador Programs where people work for free and
                    enact a new level of respect for the Ambassadors helping the
                    business grow.
                </Paragraph>
            }
            imageContent={<Image fluid={bar.childImageSharp.fluid} />}
        />
    );
};

const query = graphql`
    {
        bar: file(relativePath: { eq: "bar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;

const LinkText = styled.span`
    ${({ theme }) => `
        font-family: ${theme.fonts.heading};
        margin-top: ${theme.spacing.element};
        color: ${theme.colors.primary};
    `}
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;
