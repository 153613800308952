import React from 'react';
import { Layout } from '../components';
import {
    Landing,
    Overview,
    Description,
    Apply,
    Interested,
} from '../sections/studentsAmbassador';

const StudentsAmbassadorPage = () => (
    <Layout title="Students Ambassador">
        <Landing />
        <Overview />
        <Description />
        <Apply />
        <Interested />
    </Layout>
);

export default StudentsAmbassadorPage;
