import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { SectionTemplate } from '../../components';
import mask from '../../images/blob-shape-2.svg';
import { media } from '../../utils/mixins';
import { Paragraph } from '@cheapreats/react-ui';

export const Landing = () => {
    const { bar } = useStaticQuery(query);
    return (
        <SectionTemplate
            headingText="Are you the type of person always looking to
            level-up yourself?"
            textComponent={
                <Paragraph
                    size="default"
                    lineHeight="1.6"
                    color="black"
                    margin="20px auto"
                >
                    Join our team and become{' '}
                    <StyledLink to="/">
                        <LinkText>CheaprEats Ambassador</LinkText>
                    </StyledLink>{' '}
                    and gain hands-on experience as you learn, plan and execute
                    real-world projects based on your interests.
                </Paragraph>
            }
            imageContent={<Image fluid={bar.childImageSharp.fluid} />}
            reverse
        />
    );
};

const query = graphql`
    {
        bar: file(relativePath: { eq: "bar.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

const Image = styled(Img)`
    mask: url(${mask});
    mask-size: cover;
    max-width: 26rem;
    max-height: 26rem;
    width: 100%;
    height: auto;
    ${media.tablet`
        max-width: 20rem;
        max-height: 20rem;
    `}
`;

const LinkText = styled.span`
    ${({ theme }) => `
        font-family: ${theme.fonts.heading};
        margin-top: ${theme.spacing.element};
        color: ${theme.colors.primary};
    `}
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`;
